export const formatters = {
  currency: new Intl.NumberFormat("de", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }),
  currencyNoDecimals: new Intl.NumberFormat("de", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
  date: {
    short: "dd.MM.yyyy",
    dateTime: "eeee, dd.MM.yy, H:mm",
    fullDay: "EEEE",
  },
}
