import React, { FC, PropsWithChildren } from "react"

import { Button } from "src/components/common/button"

import { generateTrackingEvent } from "src/helpers/tracking/generateTrackingEvent"

export type ContentfulButtonProps = PropsWithChildren<{
  link: string
  /** The tertiary variant is centered by default */
  variant?: "primary" | "secondary" | "tertiary"
  size?: "small" | "large"
  tracking?: Record<string, any> | null
}>

/**
 * Default button for Contentful usage
 */
export const ContentfulButton: FC<ContentfulButtonProps> = ({
  link,
  variant = "primary",
  size = "small",
  tracking,
  children,
}) => {
  if (!link) {
    return null
  }

  const onClick = () => {
    if (tracking) generateTrackingEvent(tracking)
  }

  return (
    <Button
      variant={variant}
      href={link}
      size={size === "large" ? "lg" : "md"}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
