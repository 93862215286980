/* eslint-disable @next/next/no-assign-module-variable */
import { Carousel as Caroucssel, IFeature, Mask } from "caroucssel"
import type {
  Options as ButtonsOptions,
  Buttons as ButtonsType,
} from "caroucssel/features/buttons"
import type {
  Options as PaginationOptions,
  Pagination as PaginationType,
} from "caroucssel/features/pagination"
import { canUseDOM } from "exenv"
import {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from "react"

import { create } from "src/helpers/bem"
import { DynamicTag } from "src/helpers/dynamic-tag"
import { TranslationFeatures, useTranslation } from "src/hooks/translation"

import styles from "./Carousel.module.scss"

const bem = create(styles, "Carousel")

const importButtons = async (
  trans: TranslationFeatures,
  options: ButtonsOptions = {},
): Promise<ButtonsType> => {
  const { Buttons } = await import(
    /* webpackChunkName: "caroucssel-buttons" */ "caroucssel/features/buttons"
  )
  const { buttons: keys } = trans.messages.components.common.carousel

  // Create instance of buttons with defaults
  return new Buttons({
    className: bem("button"),
    nextClassName: bem("button", "next"),
    nextLabel: trans.formatMessage(keys.next.label),
    nextTitle: trans.formatMessage(keys.next.title),
    previousClassName: bem("button", "prev"),
    previousLabel: trans.formatMessage(keys.prev.label),
    previousTitle: trans.formatMessage(keys.prev.title),
    ...options,
  })
}

const importPagination = async (
  trans: TranslationFeatures,
  options: PaginationOptions = {},
  hasButtons: boolean = true,
): Promise<PaginationType> => {
  const { Pagination } = await import(
    /* webpackChunkName: "caroucssel-pagination" */ "caroucssel/features/pagination"
  )
  const { pagination: keys } = trans.messages.components.common.carousel

  // Create instance of pagination with defaults
  return new Pagination({
    className: bem("pagination", { "no-chevrons": !hasButtons }),
    label: ({ index }) =>
      trans.formatMessage(keys.button.label, { index: index + 1 }),
    title: ({ index }) =>
      trans.formatMessage(keys.button.title, { index: index + 1 }),
    ...options,
  })
}

type Options = {
  hasButtons: boolean
  hasPagination: boolean
  interval?: number
  autoPlay?: boolean
  setIsAutoPlayEnabled?: Dispatch<SetStateAction<boolean>>
}

const useCarousel = (options: Options) => {
  const carousel = useRef<Caroucssel | null>(null)
  const element = useRef<Element | null>(null)
  const trans = useTranslation()

  useEffect(() => {
    let intervalTimer: NodeJS.Timeout

    if (!canUseDOM) {
      return () => undefined
    }

    ;(async () => {
      const importFeatures: Promise<IFeature>[] = []

      if (options.hasButtons) {
        importFeatures.push(importButtons(trans))
      }

      if (options.hasPagination) {
        importFeatures.push(importPagination(trans, {}, options.hasButtons))
      }

      const features = await Promise.all(importFeatures)
      if (!element.current) {
        // Do not create an instance of the carousel if the element was already
        // unmounted while lazy loading all the features...
        return
      }

      if (carousel.current) {
        // Do not create an instance of the carousel if there is already
        // one while re-rendering during lazy loading of all the features...
        return
      }

      // Avoid features undefined error when clicking multiple times in next arrow
      if (!features) return

      // Avoid carousel undefined error when scrolling on page load
      if (!carousel) return

      setTimeout(() => {
        if (element.current && !carousel.current) {
          features.push(new Mask({ className: bem("mask") }))
          carousel.current = new Caroucssel(element.current, { features })
        }
      }, 100)
    })()

    if (options.autoPlay) {
      intervalTimer = setInterval(() => {
        if (
          !!carousel.current?.index &&
          !!carousel.current?.index.length &&
          !!carousel.current?.items.length
        ) {
          const currentSlideIndex = carousel.current?.index
          const lastElementIndex =
            currentSlideIndex[currentSlideIndex.length - 1]
          const totalCarouselSlideItems = carousel.current?.items.length

          carousel.current!.index =
            lastElementIndex + 1 >= totalCarouselSlideItems
              ? [0]
              : [lastElementIndex + 1]

          options.setIsAutoPlayEnabled?.(true)
        }
      }, options.interval)
    }

    return () => {
      clearInterval(intervalTimer)
      carousel.current?.destroy()
      carousel.current = null
    }
  }, [carousel, element, options, trans])

  return { element, carousel }
}

export type CarouselProps = Partial<Options> &
  PropsWithChildren<{
    tagName?: keyof JSX.IntrinsicElements
    className?: string
    interval?: number
    autoPlay?: boolean
  }>

/**
 * A CSS-based Carousel
 *
 * It can have buttons, pagination dots, and autoplay.
 */
export const Carousel: FC<CarouselProps> = ({
  children,
  className,
  tagName = "div",
  interval = 3000,
  autoPlay = false,
  ...options
}) => {
  const [isAutoPlayEnabled, setIsAutoPlayEnabled] = useState(false)

  const { element, carousel } = useCarousel({
    hasButtons: false,
    hasPagination: false,
    interval,
    autoPlay,
    setIsAutoPlayEnabled,
    ...options,
  })

  const shouldHideButtons =
    carousel.current && carousel.current.pages.length < 2

  const modifier = {
    "hide-buttons": !!shouldHideButtons,
  }

  const extraClassNames = isAutoPlayEnabled
    ? className && `${className}__container autoplay`
    : className && `${className}__container`

  return (
    <div className={bem(undefined, modifier, className)}>
      <DynamicTag
        ref={element}
        tagName={tagName}
        className={bem("container", undefined, extraClassNames)}
      >
        {children}
      </DynamicTag>
    </div>
  )
}
