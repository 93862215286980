import {
  AllHTMLAttributes,
  FC,
  RefAttributes,
  createElement,
  forwardRef,
} from "react"

export type DynamicTagProps = { tagName: string } & JSX.IntrinsicAttributes &
  RefAttributes<Element | HTMLOrSVGElement> &
  AllHTMLAttributes<Element | HTMLOrSVGElement>

export const DynamicTag: FC<DynamicTagProps> = forwardRef(
  ({ tagName, ...props }, ref) => createElement(tagName, { ...props, ref }),
)

DynamicTag.displayName = "DynamicTag"
