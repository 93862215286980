import React, { FC } from "react"

import { QuoteTile, QuoteTileProps } from "../../elements/quoteTile"
import {
  EditorialTeaser,
  EditorialTeaserProps,
} from "src/components/cck/elements/editorialTeaser"
import { Headline, HeadlineProps } from "src/components/cck/elements/headline"
import {
  InstructionalStep,
  InstructionalStepProps,
} from "src/components/cck/elements/instructionalStep"
import {
  IntroText,
  IntroTextProps,
} from "src/components/cck/elements/introText"
import {
  SalesTeaser,
  SalesTeaserProps,
} from "src/components/cck/elements/salesTeaser"
import { Carousel } from "src/components/common/carousel"

import { create } from "src/helpers/bem"

import styles from "./SliderModule.module.scss"

const bem = create(styles, "SliderModule")

export type SliderModuleTiles = {
  editorialTeasers?: EditorialTeaserProps[]
  salesTeasers?: SalesTeaserProps[]
  instructionalSteps?: InstructionalStepProps[]
  quoteTiles?: QuoteTileProps[]
}

export const SliderModuleTiles: FC<SliderModuleTiles> = ({
  editorialTeasers,
  instructionalSteps,
  salesTeasers,
  quoteTiles,
}) => {
  if (editorialTeasers?.length) {
    return (
      <>
        {editorialTeasers.map((editorialTeaser, index) => (
          <EditorialTeaser key={index} size="sm" {...editorialTeaser} />
        ))}
      </>
    )
  }

  if (salesTeasers?.length) {
    return (
      <>
        {salesTeasers.map((salesTeaser, index) => (
          <SalesTeaser key={index} size="sm" {...salesTeaser} />
        ))}
      </>
    )
  }

  if (instructionalSteps?.length) {
    return (
      <>
        {instructionalSteps.map((instructionalStep, index) => (
          <InstructionalStep
            key={index}
            tileNumber={`${++index}`}
            {...instructionalStep}
          />
        ))}
      </>
    )
  }

  if (quoteTiles?.length) {
    return (
      <>
        {quoteTiles.map((quoteTile, index) => (
          <QuoteTile key={index} {...quoteTile} />
        ))}
      </>
    )
  }

  return null
}

export type SliderModuleProps = {
  /** A Headline item */
  headline?: HeadlineProps
  /** A IntroText item */
  introText?: IntroTextProps
  /** If true, the clickable arrows are hidden */
  hideChevrons?: boolean
  /** If true, the slider scrolls automatically */
  hasAutoscroll?: boolean
  /** How long should the slider wait until the next slide, in milliseconds */
  scrollInterval?: number
} & SliderModuleTiles

/**
 * Sliders are used to display information and teasers or different types of tiles in a compact form.
 *
 * They can run automatically and/or be actively controlled by the users.
 *
 * They can have diverse items as content.
 *
 * See:
 * - [Editorial Teaser Slider](../?path=/docs/cck-sections-slider-module-editorial-teaser--docs)
 * - [Instructional Steps Slider](../?path=/docs/cck-sections-slider-module-instructional-step--docs)
 * - [Quote Tiles Slider](../?path=/docs/cck-sections-slider-module-quote-tiles--docs)
 * - [Sales Teaser Slider](../?path=/docs/cck-sections-slider-module-sales-teaser--docs)
 *
 * The main difference between the [Slider](../?path=/docs/cck-sections-slider-module--docs) and the [Teaser](..//?path=/docs/cck-sections-teaser-module--docs) module is: the Slider slides and the Teaser Module don't — it breaks to the next line in smaller screens.
 */
export const SliderModule: FC<SliderModuleProps> = ({
  headline,
  introText,
  editorialTeasers,
  instructionalSteps,
  salesTeasers,
  quoteTiles,
  hideChevrons,
  hasAutoscroll = false,
  scrollInterval = 8000,
}) => {
  if (
    !editorialTeasers?.length &&
    !salesTeasers?.length &&
    !instructionalSteps?.length &&
    !quoteTiles?.length
  ) {
    return null
  }

  return (
    <div className={bem()}>
      {headline && (
        <div className={bem("headline")}>
          <Headline {...headline} />
        </div>
      )}

      {introText?.children && (
        <div className={bem("intro-text")}>
          <IntroText {...introText} />
        </div>
      )}

      <Carousel
        tagName="ul"
        className={bem("list")}
        hasPagination
        hasButtons={!hideChevrons}
        autoPlay={hasAutoscroll}
        interval={scrollInterval}
      >
        <SliderModuleTiles
          editorialTeasers={editorialTeasers}
          instructionalSteps={instructionalSteps}
          salesTeasers={salesTeasers}
          quoteTiles={quoteTiles}
        />
      </Carousel>
    </div>
  )
}
