import React, { FC, HTMLAttributes, PropsWithChildren } from "react"

import { create } from "src/helpers/bem"

import styles from "./Headline.module.scss"

const bem = create(styles, "Headline")

type HedlineHtmlProps =
  | HTMLAttributes<HTMLDivElement>
  | HTMLAttributes<HTMLHeadingElement>

type HeadlineMap = Record<string, (props: HedlineHtmlProps) => JSX.Element>

export type HeadlineProps = PropsWithChildren<{
  id: string
  align?: "left" | "right" | "center"
  level?: "0" | "1" | "2" | "3" | "4" | "5"
  /** Adds a yellow semicircle to the left of the headline */
  highlighted?: boolean
}>

export const Headline: FC<HeadlineProps> = ({
  id,
  align = "left",
  level = "0",
  highlighted,
  children,
}) => {
  const headlineMap: HeadlineMap = {
    0: (props) => <div {...props} role="heading" aria-level={1} />,
    1: (props) => <h1 {...props} />,
    2: (props) => <h2 {...props} />,
    3: (props) => <h3 {...props} />,
    4: (props) => <h4 {...props} />,
    5: (props) => <h5 {...props} />,
  }

  const HeadlineElement = headlineMap[level]

  const modifiers = {
    [align]: true,
    [level]: true,
    highlighted: !!highlighted,
  }

  return (
    <HeadlineElement id={id} className={bem(undefined, modifiers)}>
      {children}
    </HeadlineElement>
  )
}
