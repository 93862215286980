import { FC, useMemo } from "react"

import { Image } from "src/components/common/image"
import {
  createContentfulImageSources,
  ImageSourceOptions,
  ImageSourceSizes,
  ImageType,
} from "src/helpers/contentful/createContentfulImageSources"

export const defaultImageSizes: ImageSourceSizes = [
  { maxScreenSize: 480, width: 480, scales: [1, 2] },
  { maxScreenSize: 640, width: 640, scales: [1, 2] },
  { maxScreenSize: 750, width: 750, scales: [1, 2] },
  { maxScreenSize: 828, width: 828, scales: [1, 2] },
  { maxScreenSize: 1080, width: 1080, scales: [1, 1.5] },
  { maxScreenSize: 1280, width: 1280, scales: [1, 1.5] },
  { maxScreenSize: 1440, width: 1440, scales: [1] },
  { maxScreenSize: 1920, width: 1920, scales: [1] },
]

export const defaultImageOptions: ImageSourceOptions = {
  format: "webp",
  fit: "fill",
  quality: 70,
}

const graphicImageOptions: ImageSourceOptions = {
  format: "webp",
  fit: "pad",
  quality: 100,
}

export interface ContentfulImageProps {
  url?: string
  title?: string
  /** Image description. Also, this will be displayed in the `alt` tag (used by screen readers) */
  description?: string
  className?: string
  /** Currently, `alt` is overriden by `description` */
  alt?: string
  imageSizes?: ImageSourceSizes
  imageOptions?: ImageSourceOptions
  /**
   * - Photo - image will crop according to parent.
   * - Graphic - image will scale. Use only with icons or graphics with transparent background
   * */
  imageType?: ImageType
  loading?: "eager" | "lazy"
}

export const ContentfulImage: FC<ContentfulImageProps> = ({
  url,
  title,
  description,
  className,
  alt,
  imageSizes = defaultImageSizes,
  imageOptions = defaultImageOptions,
  imageType,
  loading,
}) => {
  const options: ImageSourceOptions = useMemo(
    () => (imageType === "graphic" ? graphicImageOptions : imageOptions),
    [imageOptions, imageType],
  )

  const sources = useMemo(
    () => createContentfulImageSources(url, imageSizes, options),
    [url, imageSizes, options],
  )

  return (
    <Image
      className={className}
      sources={sources}
      alt={alt ?? (description ? description : (title ?? ""))}
      title={title}
      loading={loading}
    />
  )
}
